export const frFrContactTranslation = {
    contact: {
        title: 'Gardons le contact',
        subtitle1: `Que vous ayez besoin d'un coup de main ou simplement pour dire "Bonjour"...`,
        subtitle2: 'Laissez nous un message, nous vous recontacterons le plus rapidement possible.',
        textareaPlaceholder: 'Bonjour,\n\nJe vous envoie ce message car …',
        buttonSend: 'Envoyer',
        address: {
            title: 'Adresse',
            address: 'SAS FAST <BR />7 boulevard Nominoë<BR />35740 PAC&Eacute;'
        },
        phone: {
            title: 'Téléphone',
            phone: '+33 9 71 00 50 50'
        },
        mail: {
            title: 'Mail',
            email: 'contact@agri-maker.com'
        },
        emailCC: 'Recevoir une copie de ce message dans ma boîte mail « {{ userEmail }} ».',
        messageSend: 'Votre message a bien été envoyé'
    }
};
