<section class="menu">
    <div class="box-logo">
        <img [src]="'/assets/img/logo-white.png'" class="logo" alt="logo">
    </div>
    <div class="border"></div>
    <nav class="navigation">
        <ul>
            <li *ngFor="let navigation of visibleNavigationList" routerLinkActive="selected"
                [routerLink]="[navigation.route]">
                <i class="material-icons-outlined">{{navigation.icon}}</i><span>{{navigation.title | translate}}</span>
            </li>
        </ul>
    </nav>
</section>