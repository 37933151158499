import { LoginService } from './../../services/login.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Credential } from '../../models/credential.interface';
import { catchError, flatMap, mergeMap } from 'rxjs/operators';
import { throwError, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  errorMessage: string;

  private sub$: Subscription[];


  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loginService: LoginService) {
    this.sub$ = [];
  }

  ngOnInit() {
    this.errorMessage = this.route.snapshot.params.error;
  }

  onLogin(credential: Credential) {
    this.sub$.push(this.loginService.login(credential).pipe(
      flatMap(() => this.loginService.getUserInfo()),
      mergeMap((user) => this.loginService.getUserCompanies(user.id)),
      catchError((error) => {
        this.errorMessage = error.error.Message || error.error.message || '';
        return throwError(error);
      })).subscribe(() => {
        this.router.navigate(['/']);
      }));
  }

  public ngOnDestroy() {
    this.sub$.forEach(subscription => subscription.unsubscribe());
  }
}
