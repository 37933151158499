import { frFrBillsTranslation } from '../bills/lang/fr-FR';
import { frFrBoardTranslation } from '../board/lang/fr-FR';
import { frFrPersonTranslation } from '../persons/lang/fr-FR';
import { frFrContactTranslation } from './../contact/lang/fr-FR';
import { frFRNotificationsTranslation } from './../notifications/lang/fr-FR';
import { frFrResetPasswordTranslation } from './../reset-password/lang/fr-FR';

export const frFrGlobalTranslation = {
    global: {
        lang: 'Français',
        validation: {
            invalidField: 'Veuillez saisir un {{fieldName}} valide.'
        },
    },
    header: {
        disconnected: 'Me Connecter',
        compagny: 'Entreprise',
        disconnect: 'Déconnexion'
    },
    navigation: {
        dashboard: 'Tableau de bord',
        users: 'Gestion des abonnés',
        bills: 'Facturation',
        contact: 'Contact',
        notifications: 'Messages abonnés',
    },
    cookie: {
        disclaimer: 'Ce site utilise des cookies pour vous assurer la meilleure expérience possible.',
        gotIt: 'J\'ai compris!'
    }
};

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

const importTranslation = {};
// add dependencies here: mergeDeep(importTranslation, yourLang1, yourLang2);
mergeDeep(
    importTranslation,
    frFrGlobalTranslation,
    frFrContactTranslation,
    frFrBoardTranslation,
    frFrPersonTranslation,
    frFrBillsTranslation,
    frFrResetPasswordTranslation,
    frFRNotificationsTranslation
);
export const translation = importTranslation;
