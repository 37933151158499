<div class="app-container">
  <app-menu *ngIf="getUserInfo()| async as userInfo" [userInfo]="userInfo"></app-menu>
  <div class="app-content">
    <app-login-banner *ngIf="getUserInfo()| async as userInfo" [userInfo]="userInfo" (logout)="onLogout()">
    </app-login-banner>
    <router-outlet></router-outlet>
  </div>
</div>
<app-progress-bar [hidden]="!isLoading"></app-progress-bar>

<app-cookie-consent [defaultLabels]="getTranslatedLabels()"></app-cookie-consent>