import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private readonly httpClient: HttpClient
    ) { }

    private config: Object = null;

    public getConfig(key: any) {
      return this.config[key];
    }

    init() {
      return new Promise((resolve, reject) => {
        this.httpClient.get('/assets/config/environment.json')
        .subscribe(config => {
          this.config = config;
          resolve(true);
        });
      });
    }
  }
