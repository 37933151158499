export const frFRNotificationsTranslation = {
  generic: {
    yes: 'Oui',
    no: 'Non',
    subscribers: 'Abonnés',
    copied: 'Copie dans le presse-papier effectuée!',
    error: 'Erreur',
  },
  notifications: {
    board: {
      noSubscribers: "Vous n'avez pas d'abonnés, vous ne pouvez pas accéder à cette page.",
      navDesc: 'Message sélectionné',
      noSelection: 'Pas de message sélectionné',
      noTargets: "Le message n'a été envoyé à aucun abonné.",
      noTargetsHelp: "Veuillez réessayer ultérieurement. Si le problème persiste, vous pouvez contacter l'assistance Agri-Maker",
      navCounterDesc: {
        title: 'Publications restantes',
        subtitle: 'Provision mensuelle'
      },
      navCreateButton: 'Nouveau message',
      status: {
        refused: 'Notification refusée',
        awaitingValidation: 'En attente de validation',
        validatedModel: 'Modèle validé le ',
        awaitingPublication: 'Publication prévue le ',
        awaitingUpload: 'En cours de publication',
        published: 'Publiée le ',
      },
      empty: "Vous n'avez pas encore créé de notification. Pour en ajouter une, veuillez cliquer sur le buton \"Nouveau message\" en haut à droite de l'écran.",
      loading: 'Chargement des notifications...',
      loadingTemplates: 'Chargement des modèles...',
      loadingOffers: 'Chargement des offres...',
      targetAllSubscribed: 'Tout abonnés',
      dialog: {
        title: 'Attention',
        subtitle: 'Supprimer votre message',
        paraphOne: "Bien sûr, les utilisateurs ayant déjà vu votre communication ne l'oublierons pas… Mais pour ceux ne l'ayant pas encore vu, votre message sera supprimé :",
        paraphTwo: "- Du Panneau de Notification de l'ensemble de vos abonnés.",
        paraphThree: "- Du service Mes Messages de l'ensemble de vos abonnés.",
        paraphFour: "Plus aucun de vos abonnés ne pourra consulter votre communication, est-ce bien ce que vous souhaitez ?",
        cancel: "Non, conserver mon message",
        confirm: "Oui, je supprime",
      },
      notificationRefused: {
        title: 'Votre message a été refusé par la modération.',
        motive: 'Motif du refus',
        text: 'Vous pouvez modifier votre message et le soumettre à nouveau pour validation, ou le supprimer.',
        deleteButton: 'Supprimer le message',
        editButton: 'Modifier le message',
      },
      notificationAwaitingValidation: {
        title: 'Votre message est en attente de validation par la modération.',
        text: `Une fois validé, votre message sera publié sur le Panneau de Notification de vos abonnés.
        Vous pourrez alors consulter les statistiques d'engagement de votre message.`,
      },
      notificationAwaitingPublication: {
        title: 'Votre message est en attente de publication.',
        text: `Lorsque la date de publication sera passée, il sera envoyé aux exploitations ciblées.
        Une fois publié, vous pourrez alors consulter les statistiques d\'engagement de votre message.`,
      },
      notificationAwaitingUpload: {
        title: 'Votre message est presque publié !',
        text: `Le processus de publication peut prendre jusqu\'à 24h.
        Une fois publié, vous pourrez alors consulter les statistiques d\'engagement de votre message.`,
      },
      targeting: {
        all: 'Tout abonnés',
        model: 'Modèle pour notifications individuelles',
        singleDepartment: 'Département',
        multipleDepartments: 'Départements',
      }
    },
    stats: {
      apiUrl: {
        title : "URL de l'API",
        desc: "Remplacez <b><i>{ID_EXPLOITATION}</i></b> par l'id de l'exploitation concernée dans votre requête.",
      },
      subscribersPer: 'abonnés sur',
      average: 'moyenne',
      reads: {
        title: 'Lectures cumulées',
        subtitle: 'Nombre de lectures de votre message',
      },
      clicks: {
        title: 'Engagement cumulé',
        subtitle: 'Nombre de clics sur le bouton de votre message par vos abonnés',
      },
    },
    view: {
      editButton: 'Éditer ce message',
      deleteButton: 'Supprimer ce message',
    },
    editor: {
      title: 'Que voulez-vous faire?',
      submitting: 'Envoi du message en cours...',
      messageCard: {
        title: "Rédaction d'un message",
        titleEdit: "Modification d'un message",
        subtitle: "Envoyez rapidement un message à destination de vos abonnés en toute simplicité",
      },
      modelCard: {
        title: "Création d'un modèle",
        subtitle: "Concevez un modèle qui sera réutilisé pour toute communication automatique à destination de vos abonnés grâce à nos API dédiées",
      },
      postSuccess: {
        'title': 'Message envoyé avec succès. Il est en cours de validation par Agri-Maker.',
        'subtitle': 'Vous pouvez consulter l\'état de votre message dans le panneau de notification.',
        'button': 'Retourner au centre de notification',
      },
      form: {
        noSelection: 'Aucun(e)',
        cancel: 'Annuler',
        validate: 'Valider le message',
        invalid: 'Erreur(s) sur le formulaire',
        targeting: {
          label: 'Ciblage',
          optionOne: 'National',
          optionTwo: 'Départemental',
          colDepartmentCode: 'Département',
          colDepartmentName: 'Libellé',
          colDepartmentSubscribers: 'Abonnés',
          errorEmpty: 'Vous devez sélectionner au moins un département',
          global: 'Cibler tous les abonnés Agri-Maker?'
        },
        title: {
          label: 'Titre',
          errorEmpty: 'Vous devez saisir un titre',
          errorTooShort: 'Le titre doit comporter au moins 3 caractères',
          errorTooLong: 'Le titre doit comporter au maximum 50 caractères',

        },
        publicationDate: {
          label: 'Date de publication',
          buttonText: 'Immédiatement',
          errorEmpty: 'Vous devez saisir une date de publication',
        },
        image: {
          label: 'Image d\'illustration',
        },
        message: {
          label: 'Texte de votre message',
          errorEmpty: 'Vous devez saisir un texte',
          errorTooShort: 'Le texte doit comporter au moins 10 caractères',
          errorTooLong: 'Le texte doit comporter au maximum 500 caractères',
        },
        serviceButton: {
          label: 'Afficher un bouton de lancement du service?',
        },
        buttonText: {
          label: "Texte sur le bouton d'action",
          errorEmpty: 'Vous devez saisir un texte si le bouton est activé',
        },
        buttonUrl: {
          label: "URL de redirection",
          errorEmpty: 'Vous devez saisir une URL si le bouton est activé',
        },
        externalLink: {
          label: "URL externe à Agri-Maker?",
        },
        notificationTemplate: {
          title: 'Choix du type de message',
          label: 'Modèle'
        },
        offer: {
          title: 'Ciblage par Offre',
          label: 'Offre'
        },
        dialogCancel: {
          title: 'Attention',
          subtitle: 'Annulation de la création de votre message',
          text: `Si vous cliquez sur confirmer, la création de votre message sera annulée.
          Les données que vous avez saisi seront perdues.`,
          cancelPrompt: 'Voulez-vous vraiment annuler la création de votre message?',
        },
        dialogSubmit: {
          title: 'Récapitulatif',
          subtitle: 'Envoi de votre message',
          text: `Si vous cliquez sur confirmer, votre message sera envoyé à Agri-Maker pour validation.
          Lorsqu'il sera validé, il sera publié au plus tôt le: `,
          modelText: `Si vous cliquez sur confirmer, votre modèle sera envoyé à Agri-Maker pour validation.
          Lorsqu'il sera validé, il sera disponible pour l'envoi de messages par API.`,
          countDescStart: 'Ce message sera envoyé à ',
          countDescEnd: ' abonnés',
          infoPrompt: 'Voulez-vous vraiment envoyer votre message?',
          submitting: 'Envoi du message en cours...',
          requestError: 'Une erreur est survenue lors de l\'envoi de votre message',
          requestErrorSuggestion: `Veuillez réessayer ultérieurement.
          Si le problème persiste, vous pouvez contacter l'assistance Agri-Maker via l'onglet Contact du portail.`,
          close: 'Fermer',
        },
        contactOptions: {
          label: 'Envoyer une copie par mail aux utilisateurs?',
          mailUrl: 'Url du bouton du mail'
        }
      },
    },
    dialogs: {
      modelDescription: {
        title: "Qu'est-ce qu'un modèle de notification?",
        content: `Un modèle de notification est un message que vous pouvez réutiliser pour communiquer avec vos abonnés.
          Une fois créé, vous pourrez l'envoyer à des abonnés individuels par API.
          Les informations nécessaires pour la publication par API sont disponibles sur le détail de la notification.

          Si vous souhaitez simplement envoyer un message à vos abonnés, vous pouvez utiliser le formulaire de création de message à la place.`,
      },
      help: {
        title: 'Aide',
        close: 'Fermer'
      },
      targeting: {
        subtitle: "Ciblage de votre message",
        content: `Cette option définit la cible de votre message. Quelque soit votre sélection, le potentiel total de visibilité sera précisé:
        <br/><br/><b>"National" :</b> Sélectionne l'ensemble de vos abonnés sur tout le territoire national.
        <br/><b>"Départemental" :</b> Sélectionne vos abonnés selon le département de leurs exploitations. Vous pouvez sélectionner plusieurs départements.`
      },
      title: {
        subtitle: "Titre de votre message",
        content: "Titre de votre message qui sera affiché en premier. Il doit être clair tout en restant succin."
      },
      publicationDate: {
        subtitle: "Date de publication de votre message",
        content: `Précise la date à laquelle le message commencera à être visible dans le panneau de notification sur la page d'accueil.<br/><br/>
        Si vous souhaitez une publication immédiate, cochez la case <b>"Immédiatement":</b> votre annonce sera alors publiée dès validation par la modération.`
      },
      image: {
        subtitle: "Image d\'illustration de votre message",
        content:"Une seule illustration est disponible par message. La taille s'ajuste automatiquement à l'espace disponible. Vous pouvez choisir l'image seulement parmi la sélection proposée."
      },
      message: {
        subtitle: "Corps de votre message",
        content: "La largueur de la description s'adapte automatiquement à l'espace d'affichage disponible. Tout comme pour votre titre: soyez clair mais succin! Le corps de votre message ne peut excéder 500 caractères."
      },
      serviceButton: {
        subtitle: "Bouton de redirection vers votre service",
        content: "Votre message peut inclure un Bouton d'Action : celui-ci redirige l'utilisateur vers votre service par défaut."
      },
      buttonText: {
        subtitle: "Libellé du bouton d'action",
        content: "Message sur le bouton de redirection. Doit être une description très courte de l'action."
      },
      buttonUrl: {
        subtitle: "Lien de redirection du bouton d'action",
        content: "URL de destination sur laquelle le bouton d'action du message renvoie. Par défaut, cette URL ne peut renvoyer qu'à un portail de la Boutik ou une page du portail Agri-Maker."
      },
      externalUrl: {
        subtitle: "Permet l'utilisation d'une URL externe à Agri-Maker sur le bouton d'action.",
        content: "Indique si l'URL dans le bouton d'action renvoie sur un site extérieur à Agri-Maker ou non."
      },
      sendMail: {
        subtitle: "Envoyer une copie du message par mail",
        content: "Permet d'envoyer une copie du message par mail aux utilisateurs ciblés par votre notification."
      }
    }
  }
}
