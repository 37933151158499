import { Compagny } from './../../shared/models/compagny.model';
import { UserInfo } from './../models/user-info.interface';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Credential } from '../models/credential.interface';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoginService {

  private readonly apiUrl: string;

  userInfo: BehaviorSubject<UserInfo>;
  companyId: string;

  authenticationChange: EventEmitter<void>;

  constructor(private readonly httpClient: HttpClient) {
    this.apiUrl = environment.API_BASE_URL;
    this.userInfo = new BehaviorSubject(null);
    this.companyId = null;
    this.authenticationChange = new EventEmitter<void>();
  }

  login(credential: Credential): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/login`, credential);
  }

  logout(): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/logout`, {}).pipe(tap(() => {
      this.userInfo.next(null);
      this.companyId = null;
    }));
  }

  getUserInfo(): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(`${this.apiUrl}/users/me`).pipe(tap((infos) => {
      this.userInfo.next(infos);
      this.authenticationChange.emit();
    }));
  }

  getUserCompanies(userId: number, checkBilling: boolean = false): Observable<Compagny[]> {

    const params = new HttpParams()
      .set('checkBilling', checkBilling.toString());

    return this.httpClient.get<Compagny[]>(`${this.apiUrl}/companiesAdmin/users/${userId}/companies`, { params });

  }

  isAuthorized(roles: string[]): boolean {
    if (this.userInfo.value && roles.length > 0) {
      return roles.some((role) => {
        return this.userInfo.value.claims.findIndex(claim => claim.role.toLowerCase() === role.toLowerCase()
          && claim.scope.toLowerCase() === 'company') !== -1;
      });
    }
    return false;
  }

}
