import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Credential } from '../../models/credential.interface';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Input() error: string | null;

  @Output() submitLogin = new EventEmitter<Credential>();

  formLogin: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  });

  submit() {
    if (this.formLogin.valid) {
      this.submitLogin.emit(this.formLogin.value as Credential);
    }
  }
}

