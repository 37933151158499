import { Injectable, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

const enum PanelClassStyle {
  SUCCESS = 'style-success',
  ERROR = 'style-error'
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    public snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) { }

  public open(message, action = 'success', duration = 3000) {
    this.zone.run(() => {
      const snackBarConfig = new MatSnackBarConfig();
      snackBarConfig.duration = duration;

      if (action === 'success') {
        snackBarConfig.panelClass = PanelClassStyle.SUCCESS;
      } else {
        snackBarConfig.panelClass = PanelClassStyle.ERROR;
      }

      this.snackBar.open(message, action, snackBarConfig);
    });
  }

}
