import { map, catchError } from 'rxjs/operators';
import { LoginService } from './../../login/services/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, NavigationExtras, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private readonly loginService: LoginService, private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentNavigation = this.router.getCurrentNavigation();
    let currentRoute = this.router.url;
    if (currentNavigation &&
      currentNavigation.extras &&
      currentNavigation.extras.state &&
      currentNavigation.extras.state.currentRoute) {
      currentRoute = currentNavigation.extras.state.currentRoute;
    }
    const navigationExtras: NavigationExtras = {
      state: {
        currentRoute
      }
    };

    // used to custom redirection
    const routerNavigateData = route.data;
    const isRouterNavigateData: boolean = routerNavigateData.routerNavigateIfConnected && routerNavigateData.routerNavigateIfNotConnected;

    return this.loginService.getUserInfo().pipe(
      map(() => {
        // is connected
        if (isRouterNavigateData) {
          this.router.navigate(['/' + routerNavigateData.routerNavigateIfConnected], navigationExtras);
        }
        return true;
      }),
      catchError(() => {
        // is not connected
        if (isRouterNavigateData) {
          this.router.navigate(['/' + routerNavigateData.routerNavigateIfNotConnected], navigationExtras);
        } else {
          this.router.navigate(['/login'], navigationExtras);
        }
        return of(false);
      })
    );
  }

}
