<div class="border-card">
  <h1>{{'forgotPassword.title' | translate}}</h1>
  <h3>{{'forgotPassword.description' | translate}}</h3>
  <form [formGroup]="formForgotPassword">
    <div>
      <mat-form-field>
        <input type="text" matInput [placeholder]="'forgotPassword.emailField' | translate" formControlName="email">
        <mat-error *ngIf="isValid('email')">
          {{'forgotPassword.emailValidationError' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="error" *ngIf="error">
      {{ error }}
    </div><a href="/login">{{'forgotPassword.back' | translate}}</a>
    <button class="button" [disabled]="emailSubmitted || !formForgotPassword.valid" type="submit" mat-flat-button
      color="accent" (click)="submit()">{{'forgotPassword.reset' | translate}}</button>
  </form>
  <p class="email-sent-message" [style.display]="emailSent ? 'flex' : 'none'">
    {{ 'forgotPassword.emailSentMessage' | translate }}</p>
</div>