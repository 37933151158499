<mat-card>
  <mat-card-title>Connexion</mat-card-title>
  <mat-card-content>
    <form [formGroup]="formLogin" (ngSubmit)="submit()">
      <p>
        <mat-form-field>
          <input type="text" matInput placeholder="Identifiant" formControlName="username">
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <input type="password" matInput placeholder="Mot de passe" formControlName="password">
        </mat-form-field>
      </p>

      <p *ngIf="error" class="error">
        {{ error }}
      </p>

      <div class="resetPassword">
        <a href="/RenewPassword">Mot de passe oublié</a>
      </div>


      <div class="button">
        <button type="submit" mat-button>Login</button>
      </div>

    </form>
  </mat-card-content>
</mat-card>