export const frFrResetPasswordTranslation = {
    forgotPassword: {
        title: 'Mot de passe oublié',
        description: 'Réinitialisez votre mot de passe',
        reset: 'Envoyer',
        emailField: 'Adresse e-mail',
        emailSentMessage: 'Un email contenant un lien de réinitialisation vous a été envoyé !',
        pageTitle: 'Vous avez oublié votre mot de passe Agri maker ?',
        emailValidationError: 'Email invalide',
        back: 'Retour'
    },
    changePassword: {
        title: 'Réinitialisez votre mot de passe',
        description: 'Saisissez votre nouveau mot de passe',
        pwdLb: 'Mot de Passe',
        confirmPassword: 'Confirmation mot de passe',
        nonMatchingPassword: 'Vous avez entré deux mots de passe différents',
        changePassword: 'Envoyer'
    }
};
