import { LoginService } from './../../services/login.service';
import { Compagny } from './../../../shared/models/compagny.model';
import { UserInfo } from './../../models/user-info.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedEventService } from '../../../shared/services/shared-event.service';

@Component({
  selector: 'app-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['./login-banner.component.scss']
})
export class LoginBannerComponent implements OnInit {

  @Input() userInfo: UserInfo;

  @Output() logout = new EventEmitter<void>();

  companies: Compagny[];
  company: string;

  constructor(private readonly loginService: LoginService, private readonly sharedEventService: SharedEventService) { }

  ngOnInit() {
    this.companies = [];
    if (this.userInfo) {
      this.loginService.getUserCompanies(this.userInfo.id).subscribe((result) => {
        this.companies = result;
        if (result && result[0]) {
          this.company = result[0].id;
          this.loginService.companyId = this.company;

          this.selectCompany(this.company);
        }
      });
    }
  }

  selectCompany(value: string) {
    const res: string = value;
    this.loginService.companyId = res;
    this.sharedEventService.companyInfos.selectedCompanyId = res;
    this.sharedEventService.companyInfos.companies = this.companies;
    this.sharedEventService.emitEvent(res);
  }

  public disconnect() {
    this.logout.emit();
  }

}
