import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {

  constructor() { }

  getConsent(key: string): boolean {
    return localStorage.getItem(`consent-${key}`) === 'true' ? true : false;
  }

  acceptConsent(key: string) {
    localStorage.setItem(`consent-${key}`, 'true');
  }

}
