export enum Roles {
    CONSULT_IDENTITY = 'consult_identity',
    CONSULT_BILL = 'consult_bill',
    CONSULT_SUBSCRIPTION = 'consult_subscription',
    CONSULT_USER = 'consult_user',
    ADMIN_IDENTITY = 'admin_identity',
    ADMIN_BILL = 'admin_bill',
    ADMIN_SUBSCRIPTION = 'admin_subscription',
    ADMIN_USER = 'admin_user',
    ADMIN_ALL = 'admin_all'
}
