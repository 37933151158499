export const ValidatorsRegexp = {
    homePhone: /^(\+33[1-5,9][0-9]{8})?$|^([0][1-5,9][0-9]{8})?$/,
    mobilePhone: /^(\+33[6-7][0-9]{8})?$|^([0][6-7][0-9]{8})?$/,
    postalCode: /^([0-9]{5})?$/,
    email: /^[^\s]+@[^\s]+\.[^\s]+$/,
    password: /^([^\s.]{8,})$/,
    smsCode: /^([0-9]{4})?$/,
    pacage: /^([0-9]{9})$/,
    siret: /^([0-9]{14})$/,
    markingCode: /^[0-9]{2}[a-zA-Z0-9]{3}$/,
    numEde: /^([0-9]{8})$/
};
