export const frFrBoardTranslation = {
    board: {
        filters: {
            title: 'Filtres',
            selectAll: 'Tous',
            unselectAll: 'Aucun',
            send: 'Actualiser'
        },
        actions: {
            title: 'Actions en attente',
            subscriptionsToValidate: 'Abonnements à valider',
            unsubscriptionsToValidate: 'Désabonnements à valider',
            newSubscribers: 'Nouveaux abonnés',
            newSubscribersLastMonth: '{{value}} abonnés',
            unsubscriptionsDemands: 'Demandes de désabonnements',
            unsubscriptionsDemandsLastMonth: '{{value}} désabonnés',
            lastMonth: 'Mois précédent',
            showList: 'Afficher la liste'
        },
        users: {
            title: 'Suivi des abonnés'
        },
        subscribers: {
            title: 'Répartition des abonnements',
            subtitle: 'Répartition des offres actives de vos abonnés',
        },
        askingSubscribes: {
            title: 'Evolution des abonnements',
            subtitle: 'Nombre de demandes d\'abonnements et de désabonnements sur un mois'
        },
        visibility: {
            title: 'Visibilité',
            subtitle: 'Visites de la fiche descriptive et taux de conversion'
        },
        conversion: {
            title: 'Conversion',
            subtitle: 'Taux de demande de souscription après lecture de fiche descriptive'
        },
        subscriptionsPerDepartment: {
            title: '',
            subtitle: `Nombre d’abonnés actifs par département`,
            labelSubscriptions: 'abonnements'
        },
        activities: {
            title: 'Activité en temps réel'
        },
        uses: {
            title: 'Utilisation des services',
            subtitle: `Nombre d'accès aux services selon le jour et l'heure sur les deux derniers mois`
        }
    }
};
