import { LoginComponent } from './login/views/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './reset-password/views/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './reset-password/views/change-password/change-password.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'board',
    pathMatch: 'full'
  },
  {
    path: 'board',
    loadChildren: () => import('./board/board.module').then(m => m.BoardModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'users',
    loadChildren: () => import('./persons/persons.module').then(m => m.PersonsModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'bills',
    loadChildren: () => import('./bills/bills.module').then(m => m.BillsModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'RenewPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'RenewPassword/:token',
    component: ChangePasswordComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
