import { CookieConsentDefaultLabel } from './cookie-consent/cookie-consent/cookie-consent.component';
import { UserInfo } from './login/models/user-info.interface';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './login/services/login.service';
import { LoaderService } from './core/services/loader.service';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { translations } from './lang/lang';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked  {
  title = 'agranet-front-companies';

  isLoading = true;
  constructor(
    private readonly loaderService: LoaderService,
    private readonly loginService: LoginService,
    private readonly router: Router,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef) {

    for (const language in translations) {
      if (translations.hasOwnProperty(language)) {
        translateService.setTranslation(language, translations[language]);
      }
    }
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang(environment.defaultLocale);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use(environment.defaultLocale);
    this.loaderService.isLoading.subscribe(value => {
      this.isLoading = value;
    });
  }

  public ngOnInit() {
    this.loginService.getUserInfo().subscribe();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public getUserInfo(): BehaviorSubject<UserInfo> {
    return this.loginService.userInfo;
  }

  public onLogout() {
    this.loginService
      .logout()
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }

  getTranslatedLabels(): CookieConsentDefaultLabel {
    return {
      disclaimer: this.translateService.instant('cookie.disclaimer'),
      gotIt: this.translateService.instant('cookie.gotIt')
    };
  }


}
