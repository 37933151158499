import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyInfos } from '../models/compagny.model';

@Injectable({
  providedIn: 'root'
})
export class SharedEventService {

  companyInfos: CompanyInfos = {
    selectedCompanyId: null,
    companies: null
  };

  event: EventEmitter<any>;
  selectedIdSubject: BehaviorSubject<string>;

  constructor() {
    this.event = new EventEmitter();
    this.selectedIdSubject = new BehaviorSubject(this.companyInfos.selectedCompanyId);
  }

  emitEvent(event: string) {
    this.event.emit(event);
    this.selectedIdSubject.next(this.companyInfos.selectedCompanyId);
  }

}
