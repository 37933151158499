import { LoginService } from './../../../login/services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from '../../../shared/validators/confirm-password.validator';
import { ResetPasswordService } from '../../services/reset-password.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePasswordForm: UntypedFormGroup;
  displayPassword = false;
  displayConfirmPassword = false;
  token: string;

  nextRoute: string;

  passwordSubmitted = false;
  passwordSent = false;
  error: string;

  $subChangePassword: Subscription;
  $subRoute: Subscription;

  constructor(
    public fb: UntypedFormBuilder,
    private readonly resetPasswordService: ResetPasswordService,
    private readonly loginService: LoginService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    this.changePasswordForm = fb.group({
      password: ['', Validators.required],
      confirmPassword: ['']
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  ngOnInit() {
    this.$subRoute = this.route.params.subscribe(params => {
      this.token = params['token'];
    });
    if (window && window.history && window.history.state) {
      this.nextRoute = window.history.state.currentRoute || '/board';
    }
  }

  submit() {
    this.passwordSubmitted = true;
    if (this.changePasswordForm.valid) {
      this.passwordSent = true;
      this.$subChangePassword = this.resetPasswordService
        .changePassword(this.token, this.changePasswordForm.get('password').value)
        .pipe(
          mergeMap((res) => {
            return this.loginService.login({
              username: res.email,
              password: this.changePasswordForm.get('password').value
            });
          }),
          mergeMap(() => {
            return this.loginService.getUserInfo();
          }))
        .subscribe(
          () => {
            this.router.navigate([this.nextRoute]);
          },
          err => {
            if (err instanceof HttpErrorResponse) {
              this.error = err.error ? err.error.Message : null;
            }
          },
          () => {
            this.passwordSubmitted = false;
            this.passwordSent = false;
          }
        );
    }
  }

  ngOnDestroy() {
    if (this.$subChangePassword) {
      this.$subChangePassword.unsubscribe();
    }

    if (this.$subRoute) {
      this.$subRoute.unsubscribe();
    }
  }
}
