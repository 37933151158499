import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieConsentModule } from './cookie-consent/cookie-consent.module';
import { CoreModule } from './core/core.module';
import { AppInitService } from './core/services/app-init.service';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { SharedEventService } from './shared/services/shared-event.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInit(appInitService: AppInitService) {
  return () => appInitService.init();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    CookieConsentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    ResetPasswordModule
  ],
  providers: [TranslateService, { provide: LOCALE_ID, useValue: environment.defaultLocale },
    SharedEventService,
    DatePipe,
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppInitService],
    },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
