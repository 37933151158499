export const frFrPersonTranslation = {
    persons: {
        title: 'Abonnés',
        filters: {
            mail: 'Mail',
            name: 'Nom',
            subscriptionPeriod: 'Période inscription',
            identity: 'Identifiant de l\'entreprise',
            identityType: 'Type entreprise',
            identityTypeList: {
                farmer: 'Eleveur',
                grain_farmer: 'Céréalier'
            },
            identityStatus: 'Statut entreprise',
            identityStatusList: {
                activated: 'Activé',
                waiting: 'En attente',
                closed: 'Clos'
            },
            offerPackageOption: 'Offre / Package/Option',
            offerStatus: 'Statut Offre',
            offerStatusList: {
                activated: 'Activé',
                closed: 'Clos'
            },
            subscriptionStatus: 'Statut abonnement',
            corporateName: 'Raison sociale',
            clientNumber: 'Numéro client',
            externalManagement: 'Gestion externe',
            bool : {
                true: 'Oui',
                false: 'Non'
            }
        },
        buttons: {
            deleteFilters: 'Effacer filtres',
            search: 'Chercher',
            export: 'Exporter le résultat'
        },
        list: {
            name: 'Nom',
            email: 'Mail',
            createWith: 'Portail',
            subscriptionDate: 'Date inscription'
        },
        infos: {
            title: 'Détails Abonné',
            title2: 'Informations utilisateur',
            fullname: 'Nom, Prénom',
            email: 'Email',
            adress: 'Adresse',
            fixphone: 'N° Tél. fixe',
            mobilephone: 'N° Tél. mobile',
            dateSubscription: 'Date de souscription',
            block: {
                title: 'Compte bloqué ?',
                true: 'Oui',
                false: 'Non'
            }
        },
        identities: {
            title: 'Entreprises',
            name: 'Raison sociale',
            clientNumber: 'Numéro client',
            type: 'Type d\'entreprise',
            status: 'Statut',
            identifier: 'Entreprise',
            properties: {
                ede_number: 'Numéro d\'exploitation',
                marking_code: 'Indicatif de marquage'
            },
            paymentMethodType: 'Moyen de paiement',
            paymentMethodTypeList: {
                sepa: 'MANDAT SEPA depuis le ',
                manuel: 'MANUEL depuis le ',
                noPaymentMethod: 'Aucun moyen de paiement renseigné',
            }
        },
        subscriptions: {
            title: 'Abonnements',
            name: 'Nom',
            identity: 'Entreprise rattachée',
            identifier: 'Identifiant',
            createdAt: 'Abonnement',
            createdAtDate: 'Date abonnement',
            activatedAt: 'Activation',
            activationDate: 'Date activation',
            revocationRequiredAt: 'Dem. désactivation',
            revokedAt: 'Désabonnement',
            revocationDate: 'Date désabonnement',
            status: 'Statut',
            statusLabels: {
                revocationRequired: 'En attente désabonn.',
                revoked: 'Désabonné',
                activationRequired: 'En attente activation',
                activated: 'Activé',
                externalManagement : 'Gestion externe',                
                externalManagementSince : 'Gestion externe depuis le'
            },
            activateButton: 'Activer',
            revocateButton: 'Désabonner'
        },
        bills: {
            title: 'Factures',
            id: 'N° facture',
            dateEdition: 'Editée le',
            pretaxeAmount: 'Montant HT',
            companyPretaxeAmount: 'HT Fournisseur',
            vatAmount: 'Montant TTC',
            dueDate: 'Due le',
            status: 'Statut',
            identityNumber: 'N° client',
            dialog: {
                title: 'Détail de la facture n°',
                billDescription: 'Intitulé',
                pretaxeAmount: 'Montant HT',
                close: 'Fermer'
            }
        },
        searchHelp: {
            title: 'Aide à la recherche',
            description: 'Formulaire de recherche d\'abonnés aux services.',
            mail: {
                value: 'Mail',
                description: 'Email de l\'utilisateur'
            },
            name: {
                value: 'Nom',
                description: 'Nom de l\'utilisateur'
            },
            subscriptionPeriod: {
                value: 'Période inscription',
                description: 'Plage de date correspondant à la date d\'inscription sur Agri Maker'
            },
            identity: {
                value: 'Identifiant de l\'entreprise',
                description: 'Identifiant auprès des services (N° d\'exploitation, N° de marquage...)'
            },
            corporateName: {
                value: 'Raison sociale',
                description: 'Dénomination sociale de l\'entreprise (ex: GAEC du Verger)'
            },
            clientNumber: {
                value: 'Numéro client',
                description: 'Numéro client Agri Maker. Format : une lettre et 6 chiffres (ex: A000000)'
            },
            identityStatus: {
                value: 'Statut entreprise',
                description: 'Statut de l\'entreprise enregistré dans Agri Maker : Activée, En attente de validation, Close'
            },
            offerPackageOption: {
                value: 'Offre/Package/Option',
                description: 'Recherche des utilisateurs suivant leurs abonnements aux offres'
            },
            subscriptionStatus: {
                value: 'Statut abonnement',
                description: 'Statut de l\'abonnement : En attente d\'activation, Activé, En attente de désabonnement, Désabonné'
            },
            externalManagement: {
                value: 'Gestion externe',
                description: 'Gestion externe de l\'abonnement : Si l\'abonnement utilisateur est directement géré par le prescripteur du service'
            },
            button: {
                close: 'Fermer'
            }
        }
    }
};
