<div class="border-card">
  <h1>{{'changePassword.title' | translate}}</h1>
  <h3>{{'changePassword.description' | translate}}</h3>
  <form novalidate [formGroup]="changePasswordForm" (ngSubmit)="submit()">
    <p>
      <mat-form-field>
        <input [type]="displayPassword ? 'text' : 'password'" matInput
          [placeholder]="'changePassword.pwdLb' | translate" formControlName="password">
        <mat-icon *ngIf="!displayPassword" matSuffix (click)="displayPassword=!displayPassword">visibility_off
        </mat-icon>
        <mat-icon *ngIf="displayPassword" matSuffix (click)="displayPassword=!displayPassword">visibility</mat-icon>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <input [type]="displayConfirmPassword ? 'text' : 'password'" matInput
          [placeholder]="'changePassword.confirmPassword' | translate" formControlName="confirmPassword">
        <mat-icon *ngIf="!displayConfirmPassword" matSuffix (click)="displayConfirmPassword=!displayConfirmPassword">
          visibility_off
        </mat-icon>
        <mat-icon *ngIf="displayConfirmPassword" matSuffix (click)="displayConfirmPassword=!displayConfirmPassword">
          visibility</mat-icon>
      </mat-form-field>
    </p>

    <div class="error-container">
      <div class="error"
        *ngIf="passwordSubmitted && changePasswordForm.get('confirmPassword').dirty && changePasswordForm.get('confirmPassword').errors && changePasswordForm.get('confirmPassword').errors.ConfirmPassword">
        {{ 'changePassword.nonMatchingPassword' | translate}}
      </div>
      <div class="error" *ngIf="error">
        {{ error }}
      </div>
    </div>

    <button class="button" type="submit" mat-flat-button [disabled]="passwordSent"
      color="accent">{{'changePassword.changePassword' | translate}}</button>

  </form>
</div>