import { Component, OnInit, Input } from '@angular/core';
import { Navigation } from '../../models/navigation.model';
import { UserInfo } from '../../../login/models/user-info.interface';
import { Compagny } from '../../models/compagny.model';
import { LoginService } from '../../../login/services/login.service';
import { Roles } from '../../../login/enums/roles.enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input()
  userInfo: UserInfo;

  companies: Compagny[];

  navigationList: Navigation[];
  visibleNavigationList: Navigation[];

  constructor(private readonly loginService: LoginService) { }

  ngOnInit() {
    this.companies = [];
    if (this.userInfo) {
      this.loginService.getUserCompanies(this.userInfo.id, true).subscribe((result) => {
        this.companies = result;
        this.buildListNavigation();
        this.authorizeWidget();
        this.visibleNavigationList = this.navigationList.filter(n => n.hidden === false);
      }
      );
    }
  }

  buildListNavigation() {
    this.navigationList =
      [
        {
          key: 'dashboard',
          icon: 'dashboard',
          title: 'navigation.dashboard',
          route: '/board',
          hidden: false
        },
        {
          key: 'users',
          icon: 'account_circle',
          title: 'navigation.users',
          route: '/users',
          hidden: false
        },
        {
          key: 'bills',
          icon: 'assignment',
          title: 'navigation.bills',
          route: '/bills',
          hidden: true
        },
        {
          key: 'contact',
          icon: 'email',
          title: 'navigation.contact',
          route: '/contact',
          hidden: false
        },
        {
          key: 'notifications',
          icon: 'notifications',
          title: 'navigation.notifications',
          route: '/notifications',
          hidden: false
        }];

  }

  authorizeWidget() {
    this.navigationList.forEach(resultat => {
      resultat.hidden = !this.isWidgetAuthorized(resultat.key);
    });
  }

  private isWidgetAuthorized(key: string): boolean {
    switch (key) {
      case 'dashboard':
        return true;
      case 'contact':
        return true;
      case 'bills':
        // On recherche si on gère la facturation, si oui on l'affiche
        const allCompanyBilling = this.companies.filter(c => c.isBilling === true);
        if (allCompanyBilling && allCompanyBilling.length > 0) {
          return this.loginService.isAuthorized([
            Roles.ADMIN_BILL,
            Roles.ADMIN_ALL]);
        } else {
          return false;
        }
      case 'users':
        return this.loginService.isAuthorized([
          Roles.CONSULT_IDENTITY,
          Roles.CONSULT_SUBSCRIPTION,
          Roles.CONSULT_USER,
          Roles.ADMIN_IDENTITY,
          Roles.ADMIN_SUBSCRIPTION,
          Roles.ADMIN_USER,
          Roles.ADMIN_ALL]);

      case 'notifications':
        return this.loginService.isAuthorized([Roles.ADMIN_ALL]);
      default:
        return this.loginService.isAuthorized([Roles.ADMIN_ALL]);
    }
  }

}
