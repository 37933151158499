export const frFrBillsTranslation = {
    bills: {
        board: {
            subscribersBilling: {
                title: 'Suivi de la facturation abonnés',
                data: {
                    countBills: 'Factures',
                    vatAmountBills: 'Montant total',
                    countNotPaidBills: 'Factures impayées',
                    vatAmountNotPaidBills: 'Montant total d\'impayés',
                    lastBillingDate: 'Dernière facturation'
                }
            },
        },
        notPaid: {
            title: 'Factures impayées',
            no: 'Non',
            yes: 'Oui',
            export: 'Exporter le CSV',
            fullname: 'Nom',
            clientNumber: 'N° client',
            corporateName: 'Raison sociale',
            edeNUmber: 'N° Ede',
            id: 'N° facture',
            preTaxeAmount: 'Montant HT',
            vatAmount: 'Montant TTC',
            companyPreTaxeAmount: 'HT Fournisseur',
            dueDate: 'Due le',
            havePaymentMethod: 'Moyen Paiement',
            paymentMethodType: 'Type',
            isSubscribe: 'Est Abonné',
            supplier: 'Prescripteur',
            paymentMethod: {
                none: 'Aucun',
                manual: 'Manuel',
                directDebit: 'Prélèvement'
            },
            paid: 'Payé?'
        }
    }
};
