import { CookieConsentService } from './../services/cookie-consent.service';
import { Component, Input } from '@angular/core';

export interface CookieConsentDefaultLabel {
  disclaimer: string;
  gotIt: string;
}

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {

  isConsentAccepted: boolean;

  @Input()
  key: string;

  @Input()
  defaultLabels: CookieConsentDefaultLabel = {
    disclaimer: 'This website uses cookies to ensure you get the best experience on our website.',
    gotIt: 'Got it!'
  };

  constructor(private conkieconsentService: CookieConsentService) {
    this.isConsentAccepted = this.conkieconsentService.getConsent(this.key);
  }

  acceptConsent() {
    this.conkieconsentService.acceptConsent(this.key);
    this.isConsentAccepted = true;
  }

}
