import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { RenewPassword } from '../models/renew-password.model';


@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  API_ENDPOINT: string;

  constructor(readonly http: HttpClient) {
    this.API_ENDPOINT = environment.API_BASE_URL;
  }

  changePassword(token: string, password: string): Observable<any> {
    return this.http.put<any>(`${this.API_ENDPOINT}/users/password/${token}`, { password });
  }

  sendRenewPasswordEmail(renewPassword: RenewPassword) {
    return this.http.post<void>(`${this.API_ENDPOINT}/users/SendRenewPassWordEmail`, renewPassword);
  }
}
