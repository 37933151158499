import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ResetPasswordService } from '../../services/reset-password.service';
import { ValidatorsRegexp } from '../../../../app/shared/models/validators';
import { RenewPassword } from '../../models/renew-password.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnDestroy {
  emailSubmitted = false;
  emailSent = false;
  error: string;
  $subRenewPassword: Subscription;

  formForgotPassword: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(ValidatorsRegexp.email)
    ])
  });

  constructor(
    private readonly resetPasswordService: ResetPasswordService,
    private readonly translateService: TranslateService,
    private readonly titleService: Title) {
    this.titleService.setTitle(`${this.translateService.instant('forgotPassword.pageTitle')}`);
  }

  submit() {
    if (this.formForgotPassword.valid) {
      this.emailSubmitted = true;
      this.$subRenewPassword = this.resetPasswordService.sendRenewPasswordEmail(this.formForgotPassword.value as RenewPassword).subscribe(
        () => {
          this.emailSent = true;
          this.error = null;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            this.error = err.error ? err.error.Message : null;
          }
        },
        () => {
          this.emailSubmitted = this.emailSent;
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.$subRenewPassword) {
      this.$subRenewPassword.unsubscribe();
    }
  }

  isValid(formControlName: string): boolean {
    return !this.formForgotPassword.controls[formControlName].valid && this.formForgotPassword.controls[formControlName].touched;
  }

}
