<div class="login">
  <div *ngIf="userInfo" class="user-companies">
    <div class="company">
      <i class="material-icons" color="accent">business</i>
      <mat-select (selectionChange)="selectCompany(company)" [(value)]="company">
        <mat-option *ngFor="let company of companies" [value]="company.id">
          {{company.name}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div *ngIf="userInfo" mat-button [matMenuTriggerFor]="menu" class="user-infos">
    <i class="material-icons">account_circle</i>
    <span>{{userInfo.fullname}}</span>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="disconnect()">{{'header.disconnect' | translate}}</button>
  </mat-menu>
</div>